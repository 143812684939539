<template>
  <ui-wrap-modal
    v-bind="$attrs"
    size="md"
    hide-space
    :click-to-close="false"
  >
    <template #wrap>
      <div class="flex flex-col overflow-hidden">
        <ui-typography
          variant="h3"
          class="mb-1 pt-2 text-center"
        >
          {{ $t('Send {number} Files', { number: chatStore.attachments.length }) }}
        </ui-typography>
        <div
          ref="dropWrap"
          class="flex flex-col h-full relative overflow-x-auto max-h-[calc(100vh-8rem)]]"
        >
          <div class="flex flex-col lg:gap-4 gap-3 md:p-5 p-3 min-h-24 max-h-96 overflow-y-auto flex-1">
            <common-room-chat-modal-attach-file-item
              v-for="attachment in chatStore.attachments"
              :key="attachment.uid"
              :source="attachment"
              @remove="onRemoveAttachment"
            />
          </div>
          <common-room-chat-message-form
            v-if="!messageId"
            ref="modalMessageRef"
            is-modal
            :default-message="initMessage"
            class="border-t-2 border-gray-100 shrink-0 mt-auto"
            placeholder="Add a caption"
            @success="onSuccess"
          />
          <div
            v-else
            class="flex flex-wrap justify-center gap-3 px-5 pb-5"
          >
            <common-room-chat-upload-attachment
              v-slot="{ inputId, chooseDisabled }"
              is-modal
            >
              <ui-button
                tag="label"
                :for="inputId"
                :disabled="chooseDisabled"
                text="Add"
                icon-left="add"
                class="min-w-40"
                variant="accent-outline"
              />
            </common-room-chat-upload-attachment>
            <ui-button
              text="Upload"
              icon-left="upload"
              class="min-w-40"
              @click="onSuccess"
            />
          </div>
          <common-room-chat-upload-attach-drop-zone
            v-if="isDrop"
            small
          />
        </div>
      </div>
    </template>
  </ui-wrap-modal>
</template>

<script setup lang="ts">
  import useValidateFile from '~/composables/useValidateFile';

  type Props = {
    messageId?: number;
    initMessage?: string;
  };
  defineProps<Props>();
  const emit = defineEmits(['update:modelValue', 'success']);
  const { onSuccess, onClose } = createModalState(emit);
  const chatStore = useChatStore();
  const modalMessageRef = ref();
  const { dropWrap, isDrop } = useValidateFile(
    computed(() => ({
      multiple: true,
      resetOnSelect: true,
      attachmentFilesCount: chatStore.attachments.length
    })),
    {
      onSelect(files) {
        chatStore.onSelectAttachmentWithModal(files as File[], {
          isModal: true
        });
      }
    }
  );

  const onRemoveAttachment = () => {
    if (!chatStore.attachments.length) {
      onClose();
      if (modalMessageRef.value?.values.message) {
        chatStore.coreFormRef?.setValues({
          message: modalMessageRef.value?.values.message
        });
      }
    }
  };
</script>
