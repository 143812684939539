<template>
  <ui-fields-file-upload
    v-slot="{ inputId, chooseDisabled }"
    reset-on-select
    :accept="validAccepts"
    :disabled="disabled"
    multiple
    :attachment-files-count="attachmentFilesCount || chatStore.attachments.length"
    disabled-drop
    hide-preview
    @select="onSelectAttachment"
  >
    <slot
      :input-id="inputId"
      :choose-disabled="chooseDisabled"
    >
      <ui-button
        :for="inputId"
        variant="link"
        :disabled="disabled || chooseDisabled"
        tag="label"
        size="xs-icon"
        class="shrink-0 text-2xl w-6 h-6"
        icon-right="attach_file"
      />
    </slot>
  </ui-fields-file-upload>
</template>

<script setup lang="ts">
  type Props = {
    initMessage?: string;
    messageId?: number;
    attachmentFilesCount?: number;
    disabled?: boolean;
    isModal?: boolean;
  };
  const props = defineProps<Props>();

  const validAccepts = allTypes.accepts;
  const chatStore = useChatStore();
  const onSelectAttachment = (files: File[]) =>
    chatStore.onSelectAttachmentWithModal(files, {
      messageId: props.messageId,
      initMessage: props.initMessage,
      isModal: props.isModal
    });
</script>
