<template>
  <div class="flex gap-1.5 items-center py-2 px-2 rounded border border-gray-100 relative">
    <ui-file-preview-icon
      :class="source.previewType === 'img' ? 'md:w-16 md:h-16 w-12 h-12 object-cover' : 'w-10 h-10'"
      :src="source.previewUrl"
      :type="source.previewType"
      class="rounded-sm"
    />
    <div>
      <ui-typography
        variant="caption"
        class="break-all"
      >
        {{ source.file.name }}
      </ui-typography>
      <ui-typography
        variant="small"
        class="text-gray-300"
      >
        {{ size }}
      </ui-typography>
    </div>
    <ui-button
      class="ml-auto"
      icon-left="close"
      variant="error-link"
      size="xs-icon"
      @click="onRemove"
    />
  </div>
</template>

<script setup lang="ts">
  type Props = {
    source: MessageBufferAttachment;
  };
  const props = defineProps<Props>();
  const emit = defineEmits(['remove']);
  const chatStore = useChatStore();
  const size = computed(() => formatFileSize(props.source.file.size));

  const onRemove = () => {
    chatStore.removeSelectAttachment(props.source.uid);
    emit('remove');
  };
</script>
