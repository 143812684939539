import { autoUpdate, computePosition, hide, offset, size } from '@floating-ui/vue';
import type { ComputePositionConfig } from '@floating-ui/dom';

export const createVirtualElWithPoint = (event: MouseEvent | TouchEvent) => {
  let x = 0;
  let y = 0;

  if (event instanceof MouseEvent) {
    x = event.clientX;
    y = event.clientY;
  } else if (event instanceof TouchEvent && event.touches.length > 0) {
    x = event.touches[0].clientX;
    y = event.touches[0].clientY;
  }
  return {
    getBoundingClientRect() {
      return {
        x,
        y,
        top: y,
        left: x,
        bottom: y,
        right: x,
        width: 0,
        height: 0
      };
    }
  };
};

export const createLazyToast = async (
  component: HTMLElement,
  refEl: MaybeRef<HTMLElement | undefined>,
  options?: ComputePositionConfig
) => {
  const parentEl = unref(refEl);
  if (parentEl) {
    const positions = await computePosition(parentEl, component, {
      strategy: 'fixed',
      placement: 'top',
      ...(options || {}),
      middleware: [...(options?.middleware || [])]
    });
    Object.assign(component.style, {
      left: `${positions.x}px`,
      top: `${positions.y}px`,
      position: positions.strategy
    });

    return positions;
  }
};

const createToast = async (component: HTMLElement, refEl: MaybeRef<HTMLElement | undefined>) => {
  const parentEl = unref(refEl);
  if (parentEl) {
    return autoUpdate(parentEl, component, async () => {
      if (!parentEl) {
        return;
      }
      const positions = await createLazyToast(component, parentEl, {
        middleware: [
          offset({
            mainAxis: -8
          }),
          size({
            apply({ availableHeight, elements }) {
              const maxWidth = elements.reference.getBoundingClientRect().width;
              Object.assign(elements.floating.style, {
                maxWidth: `${maxWidth}px`,
                maxHeight: `${availableHeight}px`
              });
            }
          }),
          hide({
            strategy: 'escaped'
          })
        ]
      });

      if (positions?.middlewareData.hide) {
        Object.assign(component.style, {
          visibility: positions.middlewareData.hide.referenceHidden ? 'hidden' : 'visible'
        });
      }
    });
  }
};

export default createToast;
