<template>
  <div
    v-show="items.length"
    class="fixed bg-card pb-2 flex flex-col rounded-t-lg w-full overflow-auto z-20"
  >
    <button
      v-for="(item, index) in items"
      :key="item.id"
      type="button"
      :class="{ 'bg-card-placeholder-border': index === selectedIndex }"
      class="px-4"
      @click="onSelectItem(index)"
    >
      <span class="flex items-center py-2 gap-2.5">
        <ui-avatar
          t
          :src="item.avatar_url"
          :user-name="item.username"
          class="shrink-0"
          size="sm"
        />
        <ui-typography
          tag="span"
          variant="body2"
          no-wrap
          class="text-ellipsis overflow-hidden"
        >
          @{{ item.username }}
        </ui-typography>
      </span>
      <hr class="bg-card-placeholder-border border-0 h-px w-full" />
    </button>
  </div>
</template>

<script setup lang="ts">
  type Props = {
    items?: RoomUser[];
    command: (args: any) => void;
  };

  defineOptions({
    inheritAttrs: false
  });

  const props = withDefaults(defineProps<Props>(), {
    items: () => []
  });

  const selectedIndex = ref(0);
  const upHandler = () => {
    selectedIndex.value = (selectedIndex.value + props.items.length - 1) % props.items.length;
  };

  const downHandler = () => {
    selectedIndex.value = (selectedIndex.value + 1) % props.items.length;
  };

  const onSelectItem = (index: number) => {
    const user = props.items[index];

    if (user) {
      props.command({
        id: user.username
      });
    }
  };

  const enterHandler = () => {
    onSelectItem(selectedIndex.value);
  };

  const onKeyDown = ({ event }: { event: KeyboardEvent }) => {
    if (event.key === 'ArrowUp') {
      upHandler();
      return true;
    }

    if (event.key === 'ArrowDown') {
      downHandler();
      return true;
    }

    if (event.key === 'Enter') {
      enterHandler();
      return true;
    }

    return false;
  };

  watch(
    () => props.items,
    () => {
      selectedIndex.value = 0;
    }
  );

  defineExpose({
    onKeyDown
  });
</script>
