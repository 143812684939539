<template>
  <div
    ref="editorWrapRef"
    class="app-editor break-words overflow-auto"
  >
    <editor-content :editor="editor" />
  </div>
</template>

<script setup lang="ts">
  import Document from '@tiptap/extension-document';
  import Paragraph from '@tiptap/extension-paragraph';
  import Text from '@tiptap/extension-text';
  import Link from '@tiptap/extension-link';
  import HardBreak from '@tiptap/extension-hard-break';
  import Placeholder from '@tiptap/extension-placeholder';
  import { Editor, EditorContent, Extension, type AnyExtension } from '@tiptap/vue-3';
  import useMakeSuggestion from '~/utils/tiptap/mentionSuggestion';
  type Props = FieldProps &
    FieldVeeValidateProps & {
      floatingRef?: HTMLElement;
      getMentions?: (query: string) => Promise<any>;
    };

  const props = defineProps<Props>();

  const emit = defineEmits(['editor:blur', 'submit']);

  const editorWrapRef = ref<HTMLElement>();
  const { value, setValue } = useField(() => props.name || '', undefined, {
    initialValue: props.modelValue,
    syncVModel: props.syncVModel,
    controlled: !props.enabledControlled
  });

  const KeyboardHandler = Extension.create({
    name: 'handleSubmit',
    addKeyboardShortcuts() {
      return {
        Enter: () => {
          emit('submit');
          return true;
        }
      };
    }
  });

  const extensions: AnyExtension[] = [
    Document,
    Paragraph,
    Text,
    HardBreak,
    Placeholder.configure({
      placeholder: props.placeholder || ''
    }),
    KeyboardHandler,
    Link.configure({
      autolink: false,
      openOnClick: false,
      linkOnPaste: false,
      HTMLAttributes: {
        rel: 'noopener noreferrer',
        target: null
      }
    })
  ];

  if (props.getMentions) {
    extensions.push(
      useMakeSuggestion(
        props.getMentions,
        computed(() => props.floatingRef || editorWrapRef.value)
      )
    );
  }

  const editor = new Editor({
    content: props.modelValue || '',
    editable: !props.disabled,
    onUpdate: ({ editor }) => {
      setValue(editor.isEmpty ? '' : editor.getHTML());
    },
    onBlur: ctx => {
      emit('editor:blur', ctx);
    },
    extensions
  });

  watch(
    value,
    val => {
      if (val !== editor.getHTML()) {
        editor.commands.setContent(val || '', false);
      }
    },
    {
      immediate: true
    }
  );

  watch(
    () => props.disabled,
    val => {
      editor.setOptions({ editable: !val });
    }
  );

  onBeforeUnmount(() => {
    editor.destroy();
  });

  defineExpose({
    editor
  });
</script>

<style lang="scss">
  .app-editor .ProseMirror {
    @apply outline-none;
  }

  .tiptap {
    > * + * {
      margin-top: 0.75em;
    }
  }
  .tiptap p.is-editor-empty:first-child::before {
    content: attr(data-placeholder);
    float: left;
    color: #adb5bd;
    pointer-events: none;
    height: 0;
  }
</style>
