import type { SuggestionOptions } from '@tiptap/suggestion';
import { VueRenderer } from '@tiptap/vue-3';
import Mention from '@tiptap/extension-mention';
import { UiFieldsEditorMentionsList } from '#components';

const useMakeSuggestion = (getRequest: (query: string) => Promise<any>, editorRef: Ref<HTMLElement | undefined>) => {
  const handler = useHandleRequest();
  const debounceReq = useDebounceFn(getRequest, 400);
  const users = ref([]);
  let component: VueRenderer;

  const destroyComponent = () => {
    if (component) {
      component.el?.remove();
      component.destroy();
    }
  };

  tryOnScopeDispose(() => {
    users.value = [];
    destroyComponent();
  });

  const suggestion: Omit<SuggestionOptions, 'editor'> = {
    items: async ({ query }) => {
      if (!query || !query.trim()) {
        if (!users.value.length) {
          const { isError, data } = await handler(getRequest(query));
          users.value = data;
          return isError ? [] : users.value;
        }
        return users.value;
      }
      const { isError, data } = await handler(debounceReq(query));
      return isError ? [] : data || [];
    },
    render: () => {
      return {
        onStart(props) {
          component = new VueRenderer(UiFieldsEditorMentionsList, {
            props,
            editor: props.editor
          });
          if (component?.el) {
            document.body.append(component.el);
            createCustomToast(component.element as HTMLElement, editorRef);
          }
        },
        onUpdate(props) {
          if (component.el) {
            (component.el as HTMLElement).style.visibility = 'visible';
          }
          component?.updateProps(props);
        },
        onKeyDown(props) {
          if (props.event.key === 'Escape') {
            if (component.el) {
              (component.el as HTMLElement).style.visibility = 'hidden';
            }
            return true;
          }

          return component?.ref?.onKeyDown(props);
        },
        onExit() {
          destroyComponent();
        }
      };
    }
  };

  return Mention.configure({
    renderHTML({ options, node }) {
      return [
        'mention',
        {
          href: '/profile/' + node.attrs.id,
          'data-mention': node.attrs.id
        },
        `${options.suggestion.char}${node.attrs.id}`
      ];
    },
    suggestion
  });
};

export default useMakeSuggestion;
