<template>
  <div class="md:px-5 px-2.5 py-3 flex justify-between items-center border-b -mb-1.5 max-w-full">
    <div class="max-w-[calc(100%-2rem)]">
      <div
        :class="{
          'grid items-center gap-3 grid-cols-[2rem_calc(100%-2.25rem)]': meta.gif
        }"
      >
        <div v-if="meta.gif">
          <img
            class="aspect-square w-8 h-8 object-cover"
            :src="meta.gif"
            alt="edit gif"
          />
        </div>
        <div>
          <ui-typography
            variant="small"
            class="text-primary-hover"
          >
            <span v-if="isReply">{{ $t('Replay to') }} {{ userName }}</span>
            <span>
              {{ $t('Edit message') }}
            </span>
          </ui-typography>
          <ui-typography
            v-if="meta.content"
            variant="small"
            class="whitespace-nowrap text-ellipsis overflow-hidden"
          >
            {{ meta.content }}
          </ui-typography>
        </div>
      </div>
    </div>
    <ui-button
      class="ml-2 shrink-0"
      icon-left="close"
      variant="secondary-outline"
      size="xs-icon"
      rounded="full"
      @click="$emit('close')"
    />
  </div>
</template>

<script setup lang="ts">
  type Props = {
    isReply?: boolean;
    message: Message;
  };
  const props = defineProps<Props>();
  defineEmits(['close']);
  const userName = useUserFullName(props, 'message.author');
  const meta = computed(() => {
    return getMessagePreviewMeta(props.message);
  });
</script>
